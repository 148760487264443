import * as React from "react"
import { Link, navigate } from "gatsby"
import { Helmet } from "react-helmet"


// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  // return (
  //   <main style={pageStyles}>
  //     <title>Not found</title>
  //     <h1 style={headingStyles}>Page not found</h1>
  //     <p style={paragraphStyles}>
  //       Sorry{" "}
  //       <span role="img" aria-label="Pensive emoji">
  //         😔
  //       </span>{" "}
  //       we couldn’t find what you were looking for.
  //       <br />
  //       {process.env.NODE_ENV === "development" ? (
  //         <>
  //           <br />
  //           Try creating a page in <code style={codeStyles}>src/pages/</code>.
  //           <br />
  //         </>
  //       ) : null}
  //       <br />
  //       <Link to="/">Go home</Link>.
  //     </p>
  //   </main>
  // )

  if (typeof window !== 'undefined') {
    window.location = '/';
  }

  return (
    <>
      <Helmet 
        title="MHTTP | 404"
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap" rel="stylesheet" />
        <meta name="description" content="Page Not Found" />
        <meta name="google-site-verification" content="329vyuhJLST6Ej-QjKgi3tU8NP00BQ03TyVu4KxTl0w" />
      </Helmet>
      {null}
    </>
  )
}

export default NotFoundPage
